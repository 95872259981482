import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { MissionDetails } from '../../types';
import { RootState } from '../../configuration/setup/store';

export interface MissionsState {
    missions: MissionDetails[];
}

const initialState: MissionsState = {
    missions: [],
};

const missionsSlice = createSlice({
    name: 'missionsStore',
    initialState,
    reducers: {
        storeMissions: (state, action: PayloadAction<MissionDetails[]>) => {
            state.missions = action.payload;
        },
    },
});

export const { storeMissions } = missionsSlice.actions;

export const getMissions = (state: RootState) => state.missionsStore.missions ?? 'NO_MISSIONS_AVAILABLE';
export const findMission = (state: RootState, missionId: MissionDetails['missionId'] | undefined) =>
    state.missionsStore.missions.find((mission) => mission.missionId === missionId);

export default missionsSlice.reducer;
