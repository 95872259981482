import { FormattedMessage } from 'react-intl';
import { NavLink, Link } from 'react-router-dom';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import IframeResizer from 'iframe-resizer-react';

import {
    ROUTE_GENERAL_SETTINGS,
    ROUTE_MISSION_PROGRESS,
    ROUTE_INFRASTRUCTURE,
    ROUTE_MISSIONS,
    ROUTE_VEHICLES,
} from '../../routes/Router';
import { config } from '../../config';

const AppHeader = () => {
    const navItems = [
        {
            key: 'missions',
            route: (
                <NavLink to={ROUTE_MISSIONS}>
                    <FormattedMessage id="intl-msg:starterTemplate.sublink.missions" />
                </NavLink>
            ),
        },
        {
            key: 'vehicles',
            route: (
                <NavLink to={ROUTE_VEHICLES}>
                    <FormattedMessage id="intl-msg:starterTemplate.sublink.vehicles" />
                </NavLink>
            ),
        },
        {
            key: 'general-settings',
            route: (
                <NavLink to={ROUTE_GENERAL_SETTINGS}>
                    <FormattedMessage id="intl-msg:starterTemplate.sublink.generalSettings" />
                </NavLink>
            ),
        },
        {
            key: 'infrastructure',
            route: (
                <NavLink to={ROUTE_INFRASTRUCTURE}>
                    <FormattedMessage id="intl-msg:starterTemplate.sublink.infrastructure" />
                </NavLink>
            ),
        },
        {
            key: 'mission-progress',
            route: (
                <NavLink to={ROUTE_MISSION_PROGRESS}>
                    <FormattedMessage id="intl-msg:starterTemplate.sublink.missionProgress" />
                </NavLink>
            ),
        },
    ];

    const environment = import.meta.env.PROD ? 'production' : 'local';

    const notifications = <RioNotifications />;
    const userMenuItem = <DefaultUserMenu environment={environment} />;

    return (
        <ApplicationHeader
            label={<FormattedMessage id="intl-msg:starterTemplate.moduleName" />}
            appNavigator={<IframeResizer className="iFrameResizer" src={config.backend.MENU_SERVICE} />}
            homeRoute={<Link to={config.homeRoute || ''} />}
            navItems={navItems}
            actionBarItems={[notifications, userMenuItem]}
        />
    );
};

export default AppHeader;
