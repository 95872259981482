import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../configuration/setup/store';
import { ChargingStation } from '../../components/infrastructure/chargingStationCard/chargingStation.types';

export interface InfrastructureState {
    chargers: ChargingStation[];
}

const initialState: InfrastructureState = {
    chargers: [],
};

const infrastructureSlice = createSlice({
    name: 'infrastructure',
    initialState,
    reducers: {
        storeChargers: (state, action: PayloadAction<ChargingStation[]>) => {
            state.chargers = action.payload;
        },
    },
});

export const { storeChargers } = infrastructureSlice.actions;

export const getChargers = (state: RootState) => state.infrastructure.chargers ?? 'NO_CHARGERS_AVAILABLE';

export default infrastructureSlice.reducer;
