import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';

import { ErrorBoundary } from '../components/ErrorBoundary';
import AppLayout from '../layout/AppLayout';
import { suspendPageLoad } from './suspendPageLoad';

// Lazy load pages for better performance and automatically split the bundle accordingly
const ChargingMissionCreate = suspendPageLoad(() => import('../pages/missions/createChargingMission'));
const ChargingMissionDetails = suspendPageLoad(() => import('../pages/missions/details/chargingMissionDetails'));
const ChargingMissions = suspendPageLoad(() => import('../pages/missions/chargingMissions'));
const ChargingMissionEdit = suspendPageLoad(() => import('../pages/missions/editChargingMission'));
const GeneralSettings = suspendPageLoad(() => import('../pages/generalSettings/generalSettings'));
const Infrastructure = suspendPageLoad(() => import('../pages/infrastructure/infrastructure'));
const FleetOverview = suspendPageLoad(() => import('../pages/fleet/fleetOverview'));
const FleetVehicleDetails = suspendPageLoad(() => import('../pages/fleet/fleetVehicleDetails'));
const MissionProgress = suspendPageLoad(() => import('../pages/missionProgress/missionProgress'));

export const ROUTE_MISSIONS = '/missions';
export const ROUTE_MISSIONS_CREATE = `${ROUTE_MISSIONS}/create`;
export const ROUTE_MISSIONS_DETAILS = `${ROUTE_MISSIONS}/details`;
export const ROUTE_MISSIONS_EDIT = `${ROUTE_MISSIONS}/edit`;
export const ROUTE_VEHICLES = '/fleet';
export const ROUTE_VEHICLES_DETAILS = `${ROUTE_VEHICLES}/details`;
export const ROUTE_GENERAL_SETTINGS = '/general-settings';
export const ROUTE_INFRASTRUCTURE = '/infrastructure';
export const ROUTE_MISSION_PROGRESS = '/mission-progress';

export const routes = [
    ROUTE_MISSIONS,
    ROUTE_MISSIONS_CREATE,
    ROUTE_MISSIONS_DETAILS,
    ROUTE_VEHICLES,
    ROUTE_VEHICLES_DETAILS,
    ROUTE_GENERAL_SETTINGS,
    ROUTE_MISSIONS_EDIT,
    ROUTE_INFRASTRUCTURE,
    ROUTE_MISSION_PROGRESS,
];

const HOME_ROUTE = ROUTE_MISSIONS;

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<AppLayout />}>
            <Route path={ROUTE_GENERAL_SETTINGS} errorElement={<ErrorBoundary />} element={<GeneralSettings />} />
            <Route path={ROUTE_MISSIONS} errorElement={<ErrorBoundary />} element={<ChargingMissions />} />
            <Route path={ROUTE_VEHICLES} errorElement={<ErrorBoundary />} element={<FleetOverview />} />
            <Route path={ROUTE_MISSIONS_CREATE} errorElement={<ErrorBoundary />} element={<ChargingMissionCreate />} />
            <Route path={ROUTE_INFRASTRUCTURE} errorElement={<ErrorBoundary />} element={<Infrastructure />} />
            <Route path={ROUTE_MISSION_PROGRESS} errorElement={<ErrorBoundary />} element={<MissionProgress />} />
            <Route
                path={`${ROUTE_MISSIONS_EDIT}/:id`}
                errorElement={<ErrorBoundary />}
                element={<ChargingMissionEdit />}
            />
            <Route
                path={`${ROUTE_VEHICLES_DETAILS}/:id`}
                errorElement={<ErrorBoundary />}
                element={<FleetVehicleDetails />}
            />
            {/* If we go with separate details page, then we will keep this route */}
            <Route
                path={`${ROUTE_MISSIONS_DETAILS}/:id`}
                errorElement={<ErrorBoundary />}
                element={<ChargingMissionDetails />}
            />
            {/**
             * Custom handler to ensure the redirect after login is working. This deviates
             * from the common route handling as it is defined in the Frontend template.
             * To be investigated if this can be replaced again.
             */}
            <Route path="*" element={<Navigate to={HOME_ROUTE} replace />} />
        </Route>
    )
);
